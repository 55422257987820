<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset"  enctype="multipart/form-data">
                    <b-row>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="First Range (0-100 KM)"  vid="first_range" rules="required|numeric">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="first_range"
                                  slot-scope="{ valid, errors }"
                                  style="font-size:12px"
                              >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.transport_rent.first_range')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="first_range"
                                      v-model="transport_rent.first_range"
                                      :placeholder="$t('fertilizerConfig.transport_rent.first_range')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Second Range (101-200 KM)"  vid="second_range" rules="required|numeric">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="second_range"
                                  slot-scope="{ valid, errors }"
                                  style="font-size:12px"
                              >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.transport_rent.second_range')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="second_range"
                                      v-model="transport_rent.second_range"
                                      :placeholder="$t('fertilizerConfig.transport_rent.second_range')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Third Range (201-300 KM)"  vid="third_range" rules="required|numeric">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="third_range"
                                  slot-scope="{ valid, errors }"
                                  style="font-size:12px"
                              >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.transport_rent.third_range')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="third_range"
                                      v-model="transport_rent.third_range"
                                      :placeholder="$t('fertilizerConfig.transport_rent.third_range')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fourth Range (301-400 KM)"  vid="fourth_range" rules="required|numeric">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fourth_range"
                                  slot-scope="{ valid, errors }"
                                  style="font-size:12px"
                              >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.transport_rent.fourth_range')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="third_range"
                                      v-model="transport_rent.fourth_range"
                                      :placeholder="$t('fertilizerConfig.transport_rent.fourth_range')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Fifth Range (401-500 KM)"  vid="fifth_range" rules="required|numeric">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fifth_range"
                                  slot-scope="{ valid, errors }"
                                  style="font-size:12px"
                              >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.transport_rent.fifth_range')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="third_range"
                                      v-model="transport_rent.fifth_range"
                                      :placeholder="$t('fertilizerConfig.transport_rent.fifth_range')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="6" sm="12">
                          <ValidationProvider name="Sixth Range (501-600 KM)"  vid="sixth_range" rules="required|numeric">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="sixth_range"
                                  slot-scope="{ valid, errors }"
                                  style="font-size:12px"
                              >
                                  <template v-slot:label>
                                  {{$t('fertilizerConfig.transport_rent.sixth_range')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="third_range"
                                      v-model="transport_rent.sixth_range"
                                      :placeholder="$t('fertilizerConfig.transport_rent.sixth_range')"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { transportRentStore, transportRentUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.gettransport_rentData()
      this.transport_rent = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      transport_rent: {
        id: '',
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        location: null,
        location_bn: null
      },
      districtList: [],
      upazilaList: [],
      attachmentDemo: ''
    }
  },
  computed: {
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'transport_rent.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'transport_rent.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    }
  },
  methods: {
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    onFileChange (e) {
      this.attachmentDemo = e.target.files[0]
    },
    gettransport_rentData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: true }
      if (this.transport_rent.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${transportRentUpdate}/${this.id}`, this.transport_rent)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, transportRentStore, this.transport_rent)
      }
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (result.success) {
        this.$store.commit('mutateDropdownCommonConfig', { hasDropdownLoaded: false })
        this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: true })
    }
  }
}
</script>
